<template>
  <section class="team-block py-16">
    <div class="bg-green-light">
      <div class="constrain relative pullup">
        <div class="text-center">
          <h3 v-if="payload.titelausblenden.length === 0" v-text="payload.title" />
        </div>
        <div class="row row-12">
          <div
            v-for="(mitglied, i) in payload.mitglieder"
            :key="mitglied.id"
            v-view
            :class="{ 'in-left': i % 4 < 2, 'in-right': i % 4 > 2 }"
            class="animate col-12 sm:col-6 md:col-4"
          >
            <div class="aspect ahigh">
              <Image :src="mitglied.bild" />
            </div>
            <strong class="block font-sans mt-1" v-text="mitglied.title" />
            <div class="text-sm">
              {{ mitglied.beschreibung }}
              <span v-if="mitglied.email">
                (<a
                  :href="'mailto:' + mitglied.email"
                  target="_blank"
                  v-text="mitglied.email"
                />)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from '@/components/utils/Image.vue';

export default {
  components: { Image },
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
.row {
  margin-top: -2rem;
  justify-content: center;
}

.col-12 {
  margin-top: 2rem;
}
</style>
